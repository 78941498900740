import React from "react";
import Accordion from "~/components/accordion";
import ContentWrapper from "~/components/content-wrapper";
import H1 from "~/components/Typography/H1";
import LessonLayout from "~/layouts/lesson";
import { FlexRow } from "~/content/styledComponents";
import Button from "~/components/button";


const Page = () => {
  return (
    <LessonLayout
      current_step={2}
      lesson={"Lesson 4"}
      color={"ms"}
      total_count={15}
      module_title={undefined}
    >
      <ContentWrapper>
        <H1 underline>Step 2</H1>

        <p>Watch 2 commercials for different medications. As you watch the commercials, think about the types of information you hear.</p>
        <p>Are there any similarities in the type of information in the 2 ads? Do you notice any differences? Write your ideas in your science notebook. Watch the commercials again if you wish.</p>
        <div className="mt-5">
          <FlexRow>
            <Button
              action="secondary"
              color="purple"
              href="https://www.ispot.tv/ad/ASVF/stelara-which-you"
              icon="external"
              title="commercial 1"
            >
              Commercial 1
            </Button>
            <Button
              action="secondary"
              color="purple"
              href="https://www.ispot.tv/ad/Ipli/culturelle-finally"
              icon="external"
              title="commercial 2"
              >
              Commercial 2
            </Button>
          </FlexRow>
        </div>
        <div className="mt-4">
          <Accordion headerText="Hint" open={false}>
            <p>You do not need to know specific details about the health issues in the commercials. Just look for trends in the kind of information they give.</p>
          </Accordion>
        </div>
        <div className="mt-4">
          <Accordion headerText="Optional information" open={false}>
            <p>Psoriasis (sor-EYE-uh-sis) is a chronic skin disease. People who have the disease have patches of thick, red skin and silvery scales. Patches are often found on the elbows, knees, scalp, lower back, face, palms, and soles of the feet, but can also be other places on the body. Psoriasis occurs because the growth of skin cells speeds up.</p>
          </Accordion>
        </div>
      </ContentWrapper>
    </LessonLayout>
  )
}

export default Page;

